import React, { useState } from 'react';
import { Plus, ArrowRight, ArrowLeft, RotateCcw, Calendar } from 'lucide-react';
import { CarCard } from './CarCard';
import { AddCarModal } from './AddCarModal';
import { JoinRideModal } from './modals/JoinRideModal';
import { useRides } from '../hooks/useRides';
import { useAuth } from '../contexts/AuthContext';
import { MaterialType } from '../types';
import { SocialLinks } from './SocialLinks';

type TripDirection = 'all' | 'to' | 'from' | 'both';

interface Props {
  universityId: string;
  universityName: string;
  universityColor: string;
  whatsappUrl?: string | null;
  telegramUrl?: string | null;
  onAuthRequired: () => void;
}

export function CarList({ 
  universityId, 
  universityName, 
  universityColor,
  whatsappUrl,
  telegramUrl,
  onAuthRequired 
}: Props) {
  const { user } = useAuth();
  const { 
    rides = [], 
    loading, 
    error, 
    addRide, 
    joinRide, 
    leaveRide,
    removePassenger,
    removeMaterial,
    deleteRide,
    contributeMaterials 
  } = useRides(universityId);
  const [showAddCar, setShowAddCar] = useState(false);
  const [directionFilter, setDirectionFilter] = useState<TripDirection>('all');
  const [dateFilter, setDateFilter] = useState<string>('');
  const [selectedRide, setSelectedRide] = useState<{
    id: string;
    driverName: string;
    driverId: string;
    driverAvatar?: string | null;
    driverAlias?: string;
    carType: string;
    departureTime: string;
    availableSeats: number;
    driverMaterials?: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
    }>;
    passengers?: Array<{
      id: string;
      name: string;
      avatar_url: string | null;
      contact_info: string;
      materials?: Array<{
        id: string;
        material_type: MaterialType;
        description?: string;
      }>;
    }>;
    contributors?: Array<{
      id: string;
      name: string;
      avatar_url: string | null;
      materials: Array<{
        id: string;
        material_type: MaterialType;
        description?: string;
      }>;
    }>;
  } | null>(null);

  // Sort and filter rides
  const sortedAndFilteredRides = [...(rides || [])]
    .filter(ride => {
      if (!ride) return false;

      const passesDirectionFilter = 
        directionFilter === 'all' ? true :
        directionFilter === 'to' ? ride.trip_type === 'to' || ride.trip_type === 'both' :
        directionFilter === 'from' ? ride.trip_type === 'from' || ride.trip_type === 'both' :
        ride.trip_type === directionFilter;

      const passesDateFilter = !dateFilter || 
        new Date(ride.departure_time).toLocaleDateString() === new Date(dateFilter).toLocaleDateString();

      return passesDirectionFilter && passesDateFilter;
    })
    .sort((a, b) => new Date(a.departure_time).getTime() - new Date(b.departure_time).getTime());

  const handleAddCar = async (car: {
    car_type: string;
    total_seats: number;
    trip_type: 'to' | 'from' | 'both';
    departure_time: string;
    return_time?: string;
    driver_alias?: string;
    materials?: Array<{ type: MaterialType; description?: string }>;
  }) => {
    if (!user) {
      onAuthRequired();
      return;
    }

    try {
      await addRide({
        ...car,
        materials: car.materials?.map(m => ({
          material_type: m.type,
          description: m.description
        }))
      });
      setShowAddCar(false);
    } catch (err) {
      console.error('Error adding ride:', err);
      alert(err instanceof Error ? err.message : 'Error al añadir viaje');
    }
  };

  const handleJoinRide = async (contactInfo: string, materials?: Array<{ type: MaterialType; description?: string }>) => {
    if (!user) {
      onAuthRequired();
      return;
    }

    if (!selectedRide) return;

    try {
      await joinRide(selectedRide.id, contactInfo, materials);
      setSelectedRide(null);
    } catch (err) {
      console.error('Error joining ride:', err);
      alert(err instanceof Error ? err.message : 'Error al unirse al viaje');
    }
  };

  const handleLeaveRide = async () => {
    if (!user || !selectedRide) return;

    try {
      await leaveRide(selectedRide.id);
      setSelectedRide(null);
    } catch (err) {
      console.error('Error leaving ride:', err);
      alert(err instanceof Error ? err.message : 'Error al abandonar el viaje');
    }
  };

  const handleRemovePassenger = async (passengerId: string) => {
    if (!user || !selectedRide) return;

    try {
      await removePassenger(selectedRide.id, passengerId);
    } catch (err) {
      console.error('Error removing passenger:', err);
      alert(err instanceof Error ? err.message : 'Error al eliminar pasajero');
    }
  };

  const handleRemoveMaterial = async (materialId: string) => {
    if (!user || !selectedRide) return;

    try {
      await removeMaterial(materialId);
    } catch (err) {
      console.error('Error removing material:', err);
      alert(err instanceof Error ? err.message : 'Error al eliminar material');
    }
  };

  const handleDeleteRide = async () => {
    if (!user || !selectedRide) return;

    try {
      await deleteRide(selectedRide.id);
      setSelectedRide(null);
    } catch (err) {
      console.error('Error deleting ride:', err);
      alert(err instanceof Error ? err.message : 'Error al eliminar viaje');
    }
  };

  const handleContributeMaterials = async (materials: Array<{ type: MaterialType; description?: string }>) => {
    if (!user || !selectedRide) return;

    try {
      await contributeMaterials(selectedRide.id, materials);
      setSelectedRide(null);
    } catch (err) {
      console.error('Error contributing materials:', err);
      alert(err instanceof Error ? err.message : 'Error al contribuir materiales');
    }
  };

  const handleRideClick = (ride: typeof selectedRide) => {
    if (!user) {
      onAuthRequired();
      return;
    }
    setSelectedRide(ride);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="text-[#03E888] text-xl font-mono">Cargando viajes...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
        {error}
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 mb-6">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 w-full lg:w-auto">
            <div className="flex flex-col gap-2">
              <h2 
                className="text-xl font-semibold font-mono"
                style={{ color: universityColor }}
              >
                {universityName}
              </h2>
              <SocialLinks
                whatsappUrl={whatsappUrl}
                telegramUrl={telegramUrl}
                className="flex-shrink-0"
              />
            </div>
            
            <button
              onClick={() => user ? setShowAddCar(true) : onAuthRequired()}
              className="w-full sm:w-auto flex items-center justify-center gap-2 bg-[#03E888] hover:bg-[#02c974] text-gray-900 px-4 py-2 rounded-lg transition-colors duration-300 font-mono"
            >
              <Plus className="w-5 h-5" />
              Ofrecer viaje
            </button>
          </div>

          <div className="flex flex-col sm:flex-row gap-2 w-full lg:w-auto">
            <div 
              className="flex p-1 bg-gray-800/50 backdrop-blur-sm rounded-lg border-2 border-[#03E888]/20"
              style={{ borderColor: `${universityColor}33` }}
            >
              <button
                onClick={() => setDirectionFilter('all')}
                className={`flex-1 sm:flex-initial flex items-center justify-center gap-2 px-3 py-1.5 rounded-lg transition-all duration-300 font-mono text-sm ${
                  directionFilter === 'all'
                    ? 'bg-[#03E888] text-gray-900'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <RotateCcw className="w-4 h-4" />
                <span className="hidden sm:inline">Todos</span>
              </button>
              <button
                onClick={() => setDirectionFilter('to')}
                className={`flex-1 sm:flex-initial flex items-center justify-center gap-2 px-3 py-1.5 rounded-lg transition-all duration-300 font-mono text-sm ${
                  directionFilter === 'to'
                    ? 'bg-[#03E888] text-gray-900'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <ArrowRight className="w-4 h-4" />
                <span className="hidden sm:inline">Hacia DANA</span>
              </button>
              <button
                onClick={() => setDirectionFilter('from')}
                className={`flex-1 sm:flex-initial flex items-center justify-center gap-2 px-3 py-1.5 rounded-lg transition-all duration-300 font-mono text-sm ${
                  directionFilter === 'from'
                    ? 'bg-[#03E888] text-gray-900'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <ArrowLeft className="w-4 h-4" />
                <span className="hidden sm:inline">Desde DANA</span>
              </button>
            </div>

            <div className="relative flex-1 sm:flex-initial">
              <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
              <input
                type="date"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
                className="w-full pl-10 pr-3 py-1.5 bg-gray-800/50 backdrop-blur-sm border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono text-sm"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
        {sortedAndFilteredRides.map((ride) => {
          if (!ride || !ride.profiles) return null;

          // Get all materials for this ride
          const allMaterials = ride.ride_materials?.map(m => ({
            id: m.id,
            material_type: m.material_type,
            description: m.description,
            provider_type: m.provider_type,
            provider_id: m.provider_id,
            provider_name: m.profiles.full_name,
            provider_avatar: m.profiles.avatar_url
          })) || [];

          // Map passengers from ride_passengers
          const passengers = ride.ride_passengers?.map(rp => ({
            id: rp.passenger_id,
            name: rp.profiles.full_name,
            avatar_url: rp.profiles.avatar_url,
            contact_info: rp.contact_info,
            materials: allMaterials.filter(m => 
              m.provider_type === 'passenger' && 
              m.provider_id === rp.passenger_id
            ).map(m => ({
              id: m.id,
              material_type: m.material_type,
              description: m.description
            }))
          })) || [];

          // Map contributors (users who are neither driver nor passengers)
          const contributors = allMaterials
            .filter(m => m.provider_type === 'contributor')
            .reduce((acc, m) => {
              const existing = acc.find(c => c.id === m.provider_id);
              if (existing) {
                existing.materials.push({
                  id: m.id,
                  material_type: m.material_type,
                  description: m.description
                });
              } else {
                acc.push({
                  id: m.provider_id,
                  name: m.provider_name,
                  avatar_url: m.provider_avatar,
                  materials: [{
                    id: m.id,
                    material_type: m.material_type,
                    description: m.description
                  }]
                });
              }
              return acc;
            }, [] as Array<{
              id: string;
              name: string;
              avatar_url: string | null;
              materials: Array<{
                id: string;
                material_type: MaterialType;
                description?: string;
              }>;
            }>);

          return (
            <CarCard
              key={ride.id}
              car={{
                id: ride.id,
                driverId: ride.driver_id,
                driverAlias: ride.driver_alias,
                carType: ride.car_type,
                totalSeats: ride.total_seats,
                availableSeats: ride.available_seats,
                tripType: ride.trip_type,
                departureTime: ride.departure_time,
                returnTime: ride.return_time,
                universityId: ride.university_id,
                materials: allMaterials
              }}
              driverName={ride.profiles.full_name}
              driverAvatar={ride.profiles.avatar_url}
              passengers={passengers}
              contributors={contributors}
              currentUserId={user?.id}
              onJoinRide={() => handleRideClick({
                id: ride.id,
                driverName: ride.profiles.full_name,
                driverId: ride.driver_id,
                driverAvatar: ride.profiles.avatar_url,
                driverAlias: ride.driver_alias,
                carType: ride.car_type,
                departureTime: ride.departure_time,
                availableSeats: ride.available_seats,
                driverMaterials: allMaterials.filter(m => m.provider_type === 'driver').map(m => ({
                  id: m.id,
                  material_type: m.material_type,
                  description: m.description
                })),
                passengers,
                contributors
              })}
              universityColor={universityColor}
            />
          );
        })}
      </div>

      {showAddCar && (
        <AddCarModal
          isOpen={showAddCar}
          onClose={() => setShowAddCar(false)}
          onAdd={handleAddCar}
          universityId={universityId}
          universityColor={universityColor}
        />
      )}

      {selectedRide && (
        <JoinRideModal
          isOpen={true}
          onClose={() => setSelectedRide(null)}
          onJoin={handleJoinRide}
          onLeave={handleLeaveRide}
          onRemovePassenger={handleRemovePassenger}
          onRemoveMaterial={handleRemoveMaterial}
          onDeleteRide={handleDeleteRide}
          onContributeMaterials={handleContributeMaterials}
          ride={selectedRide}
          universityColor={universityColor}
          currentUserId={user?.id}
        />
      )}
    </>
  );
}