import React from 'react';
import { MATERIAL_TYPES, MaterialType } from '../lib/materials';
import { MaterialTag } from './MaterialTag';

interface Props {
  selected: MaterialType[];
  onToggle: (type: MaterialType) => void;
}

export function MaterialSelector({ selected, onToggle }: Props) {
  return (
    <div className="flex flex-wrap gap-2">
      {Object.entries(MATERIAL_TYPES).map(([type, info]) => (
        <button
          key={type}
          type="button"
          onClick={() => onToggle(type as MaterialType)}
          className={`transition-all duration-300 ${
            selected.includes(type as MaterialType)
              ? 'scale-110'
              : 'opacity-50 hover:opacity-100'
          }`}
        >
          <MaterialTag type={type as MaterialType} />
        </button>
      ))}
    </div>
  );
}