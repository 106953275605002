import React, { useState } from 'react';
import { X, ArrowRight, RotateCcw, Minus, Plus, Users, Package } from 'lucide-react';
import { MaterialType } from '../lib/materials';
import { MaterialSelector } from './MaterialSelector';
import { DateTimeSelector } from './DateTimeSelector';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (car: {
    car_type: string;
    total_seats: number;
    trip_type: 'to' | 'from' | 'both';
    departure_time: string;
    return_time?: string;
    driver_alias?: string;
    materials?: Array<{ type: MaterialType; description?: string }>;
  }) => void;
  universityId: string;
  universityColor: string;
}

export function AddCarModal({ isOpen, onClose, onAdd, universityColor }: Props) {
  const [form, setForm] = useState({
    driver_alias: '',
    car_type: '',
    total_seats: 4,
    trip_type: 'to' as const,
    departure_time: new Date().toISOString(),
    return_time: '',
  });
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialType[]>([]);
  const [error, setError] = useState<string | null>(null);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!form.car_type.trim()) {
      setError('El tipo de vehículo es requerido');
      return;
    }
    if (!form.departure_time) {
      setError('La hora de salida es requerida');
      return;
    }
    if (form.trip_type === 'both' && !form.return_time) {
      setError('La hora de regreso es requerida para viajes de ida y vuelta');
      return;
    }

    const formattedData = {
      ...form,
      driver_alias: form.driver_alias.trim() || undefined,
      departure_time: new Date(form.departure_time).toISOString(),
      return_time: form.return_time ? new Date(form.return_time).toISOString() : undefined,
      materials: selectedMaterials.map(type => ({ type }))
    };

    onAdd(formattedData);
    onClose();
  };

  const adjustSeats = (increment: boolean) => {
    setForm(prev => ({
      ...prev,
      total_seats: increment 
        ? Math.min(prev.total_seats + 1, 8)
        : Math.max(prev.total_seats - 1, 1)
    }));
  };

  const colorStyle = {
    '--univ-color': universityColor,
    '--univ-color-10': `${universityColor}1a`,
    '--univ-color-20': `${universityColor}33`,
  } as React.CSSProperties;

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50 overflow-y-auto">
      <div 
        className="bg-gray-800/90 border-2 border-[var(--univ-color)] rounded-xl max-w-md w-full my-4 max-h-[calc(100vh-2rem)] flex flex-col overflow-hidden"
        style={colorStyle}
      >
        <div className="bg-gray-800 px-4 py-3 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-bold text-white font-mono">Ofrecer un Viaje</h2>
            <button 
              onClick={onClose} 
              className="text-gray-400 hover:text-white p-1 rounded-lg transition-colors hover:bg-gray-700"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto">
          <div className="p-4">
            {error && (
              <div className="mb-3 p-2 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-3">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
                  Alias del Equipo (opcional)
                </label>
                <input
                  type="text"
                  value={form.driver_alias}
                  onChange={(e) => setForm({ ...form, driver_alias: e.target.value })}
                  className="w-full px-3 py-2 bg-gray-700/50 border-2 border-[var(--univ-color-20)] text-white rounded-lg focus:ring-2 focus:ring-[var(--univ-color)] focus:border-[var(--univ-color)] placeholder-gray-400 font-mono"
                  placeholder="ej. Los Rápidos"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
                  Tipo de Vehículo
                </label>
                <input
                  type="text"
                  required
                  placeholder="ej. Toyota Corolla"
                  value={form.car_type}
                  onChange={(e) => setForm({ ...form, car_type: e.target.value })}
                  className="w-full px-3 py-2 bg-gray-700/50 border-2 border-[var(--univ-color-20)] text-white rounded-lg focus:ring-2 focus:ring-[var(--univ-color)] focus:border-[var(--univ-color)] placeholder-gray-400 font-mono"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
                  Asientos Disponibles
                </label>
                <div className="flex items-center justify-center gap-4 p-4 bg-gray-700/50 border-2 border-[var(--univ-color-20)] rounded-lg">
                  <button
                    type="button"
                    onClick={() => adjustSeats(false)}
                    disabled={form.total_seats <= 1}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-gray-800/50 hover:bg-gray-800 text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Minus className="w-5 h-5" />
                  </button>
                  
                  <div className="flex items-center gap-2 min-w-[100px] justify-center">
                    <Users className="w-5 h-5 text-[var(--univ-color)]" />
                    <span className="text-2xl font-bold text-white font-mono">
                      {form.total_seats}
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={() => adjustSeats(true)}
                    disabled={form.total_seats >= 8}
                    className="w-10 h-10 flex items-center justify-center rounded-lg bg-gray-800/50 hover:bg-gray-800 text-white transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <Plus className="w-5 h-5" />
                  </button>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
                  Tipo de Viaje
                </label>
                <div className="flex gap-2 p-2 bg-gray-700/50 rounded-lg border-2 border-[var(--univ-color-20)]">
                  <button
                    type="button"
                    onClick={() => setForm({ ...form, trip_type: 'to' })}
                    className={`flex-1 py-2 px-3 rounded-lg flex items-center justify-center gap-2 transition-colors duration-300 ${
                      form.trip_type === 'to'
                        ? 'bg-[var(--univ-color)] text-gray-900'
                        : 'text-gray-400 hover:text-white'
                    }`}
                  >
                    <ArrowRight className="w-4 h-4" />
                    <span className="hidden xs:inline font-mono">Hacia DANA</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => setForm({ ...form, trip_type: 'from' })}
                    className={`flex-1 py-2 px-3 rounded-lg flex items-center justify-center gap-2 transition-colors duration-300 ${
                      form.trip_type === 'from'
                        ? 'bg-[var(--univ-color)] text-gray-900'
                        : 'text-gray-400 hover:text-white'
                    }`}
                  >
                    <ArrowRight className="w-4 h-4 rotate-180" />
                    <span className="hidden xs:inline font-mono">Desde DANA</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => setForm({ ...form, trip_type: 'both' })}
                    className={`flex-1 py-2 px-3 rounded-lg flex items-center justify-center gap-2 transition-colors duration-300 ${
                      form.trip_type === 'both'
                        ? 'bg-[var(--univ-color)] text-gray-900'
                        : 'text-gray-400 hover:text-white'
                    }`}
                  >
                    <RotateCcw className="w-4 h-4" />
                    <span className="hidden xs:inline font-mono">Ida y Vuelta</span>
                  </button>
                </div>
              </div>

              <DateTimeSelector
                label="Fecha & Hora de Salida"
                value={form.departure_time}
                onChange={(value) => setForm({ ...form, departure_time: value })}
                required
                min={new Date().toISOString()}
              />

              {form.trip_type === 'both' && (
                <DateTimeSelector
                  label="Fecha & Hora de Regreso"
                  value={form.return_time || form.departure_time}
                  onChange={(value) => setForm({ ...form, return_time: value })}
                  required
                  min={form.departure_time}
                />
              )}

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-2 font-mono flex items-center gap-2">
                  <Package className="w-4 h-4" />
                  Material que puedes aportar
                </label>
                <MaterialSelector
                  selected={selectedMaterials}
                  onToggle={(type) => {
                    setSelectedMaterials(current => 
                      current.includes(type)
                        ? current.filter(t => t !== type)
                        : [...current, type]
                    );
                  }}
                />
              </div>

              <button
                type="submit"
                className="w-full py-2 px-4 bg-[var(--univ-color)] hover:brightness-110 text-gray-900 rounded-lg transition-all duration-300 font-medium font-mono"
              >
                Ofrecer Viaje
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}