export type MaterialType = 
  | 'higiene' 
  | 'ropa' 
  | 'ninos' 
  | 'alimentos' 
  | 'mobiliario' 
  | 'herramientas'
  | 'medicamentos'
  | 'agua';

export const MATERIAL_TYPES: Record<MaterialType, { label: string; color: string }> = {
  higiene: { label: 'Higiene', color: '#00B7FF' },
  ropa: { label: 'Ropa', color: '#FF6B6B' },
  ninos: { label: 'Niños', color: '#4ECDC4' },
  alimentos: { label: 'Alimentos', color: '#F1C40F' },
  mobiliario: { label: 'Mobiliario', color: '#9B59B6' },
  herramientas: { label: 'Herramientas', color: '#E67E22' },
  medicamentos: { label: 'Medicamentos', color: '#E74C3C' },
  agua: { label: 'Agua', color: '#3498DB' }
};