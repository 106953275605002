import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import type { Database } from '../lib/database.types';

type Group = Database['public']['Tables']['groups']['Row'] & {
  ride_count?: number;
};

export function useGroups() {
  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchGroups();
  }, []);

  async function fetchGroups() {
    try {
      setLoading(true);
      
      // First get all groups
      const { data: groupsData, error: groupsError } = await supabase
        .from('groups')
        .select('*');

      if (groupsError) throw groupsError;

      // Get current timestamp in ISO format
      const now = new Date().toISOString();

      // Then get ride counts for each group, only counting future rides
      const { data: rideCounts, error: ridesError } = await supabase
        .from('rides')
        .select('university_id, departure_time')
        .gte('departure_time', now);

      if (ridesError) throw ridesError;

      // Process and combine the data
      const groupsWithCounts = groupsData.map(group => {
        const count = rideCounts?.filter(ride => ride.university_id === group.id).length || 0;
        return {
          ...group,
          ride_count: count
        };
      });

      // Sort by ride count (descending) and then alphabetically by name
      const sortedGroups = groupsWithCounts.sort((a, b) => {
        const countDiff = (b.ride_count || 0) - (a.ride_count || 0);
        if (countDiff !== 0) return countDiff;
        return a.name.localeCompare(b.name);
      });

      setGroups(sortedGroups);
    } catch (err) {
      console.error('Error fetching groups:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar grupos');
    } finally {
      setLoading(false);
    }
  }

  async function addGroup(group: Omit<Group, 'id' | 'created_at'>) {
    try {
      const { data, error } = await supabase
        .from('groups')
        .insert([group])
        .select()
        .single();

      if (error) throw error;
      
      // Add the new group to the state with ride_count of 0
      setGroups(prev => {
        const newGroups = [...prev, { ...data, ride_count: 0 }];
        return newGroups.sort((a, b) => {
          const countDiff = (b.ride_count || 0) - (a.ride_count || 0);
          if (countDiff !== 0) return countDiff;
          return a.name.localeCompare(b.name);
        });
      });
      
      return data;
    } catch (err) {
      throw err instanceof Error ? err : new Error('Error al añadir grupo');
    }
  }

  return {
    groups,
    loading,
    error,
    addGroup,
    refreshGroups: fetchGroups,
  };
}