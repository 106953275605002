import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import type { Database } from '../lib/database.types';

type University = Database['public']['Tables']['universities']['Row'] & {
  ride_count?: number;
};

export function useUniversities() {
  const [universities, setUniversities] = useState<University[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchUniversities();
  }, []);

  async function fetchUniversities() {
    try {
      setLoading(true);
      
      // First get all universities
      const { data: universitiesData, error: universitiesError } = await supabase
        .from('universities')
        .select('*');

      if (universitiesError) throw universitiesError;

      // Then get ride counts for each university
      const now = new Date().toISOString();
      const { data: rideCounts, error: ridesError } = await supabase
        .from('rides')
        .select('university_id, departure_time')
        .gte('departure_time', now);

      if (ridesError) throw ridesError;

      // Process and combine the data
      const universitiesWithCounts = universitiesData.map(univ => {
        const count = rideCounts?.filter(ride => ride.university_id === univ.id).length || 0;
        return {
          ...univ,
          ride_count: count
        };
      });

      // Sort by ride count (descending)
      const sortedUniversities = universitiesWithCounts.sort((a, b) => 
        (b.ride_count || 0) - (a.ride_count || 0)
      );

      setUniversities(sortedUniversities);
    } catch (err) {
      console.error('Error fetching universities:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar universidades');
    } finally {
      setLoading(false);
    }
  }

  async function addUniversity(university: Omit<University, 'id' | 'created_at'>) {
    try {
      const { data, error } = await supabase
        .from('universities')
        .insert([university])
        .select()
        .single();

      if (error) throw error;
      setUniversities(prev => [...prev, { ...data, ride_count: 0 }]);
      return data;
    } catch (err) {
      throw err instanceof Error ? err : new Error('Error al añadir universidad');
    }
  }

  return {
    universities,
    loading,
    error,
    addUniversity,
    refreshUniversities: fetchUniversities,
  };
}