import React, { useRef, useState, useEffect } from 'react';
import { User, Camera, Loader2, Phone } from 'lucide-react';
import { useProfile } from '../hooks/useProfile';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { COUNTRY_CODES } from '../lib/constants';

export default function ProfilePage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { profile, loading, error, updateProfile, uploadAvatar } = useProfile(user);
  const [isEditing, setIsEditing] = useState(false);
  const [form, setForm] = useState({
    full_name: '',
    description: '',
    phone: '',
    country_code: '+34'
  });
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
    if (profile) {
      const [code = '+34', number = ''] = (profile.phone || '').split(' ');
      setForm({
        full_name: profile.full_name || '',
        description: profile.description || '',
        phone: number,
        country_code: code
      });
    }
  }, [user, navigate, profile]);

  if (!user) return null;

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="text-[#03E888] text-xl font-mono">Cargando perfil...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex-1 flex items-center justify-center px-4">
        <div className="w-full max-w-2xl p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
          {error}
        </div>
      </div>
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const formattedPhone = form.phone ? `${form.country_code} ${form.phone}` : '';
      await updateProfile({
        full_name: form.full_name,
        description: form.description,
        phone: formattedPhone
      });
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating profile:', err);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setUploading(true);
      setUploadError(null);
      await uploadAvatar(file);
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setUploadError(err instanceof Error ? err.message : 'Error al subir la imagen');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="flex-1 flex items-center justify-center p-4">
      <div className="w-full max-w-2xl">
        <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border-2 border-[#03E888]/50 p-8">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-6 mb-8">
            <h2 className="text-2xl font-bold text-white font-mono">Mi Perfil</h2>
            <button
              onClick={() => setIsEditing(!isEditing)}
              className="w-full sm:w-auto px-6 py-2 bg-[#03E888] hover:bg-[#02c974] text-gray-900 rounded-lg transition-colors duration-300 font-medium font-mono"
            >
              {isEditing ? 'Cancelar' : 'Editar'}
            </button>
          </div>

          {uploadError && (
            <div className="mb-8 p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
              {uploadError}
            </div>
          )}

          <div className="flex flex-col items-center gap-8">
            <div className="relative">
              <div className={`w-32 h-32 rounded-full overflow-hidden border-4 border-[#03E888]/50 ${uploading ? 'opacity-50' : ''}`}>
                {profile?.avatar_url ? (
                  <img
                    src={profile.avatar_url}
                    alt={profile.full_name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-700 flex items-center justify-center">
                    <User className="w-16 h-16 text-gray-400" />
                  </div>
                )}
              </div>
              {!uploading && (
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="absolute bottom-0 right-0 bg-[#03E888] hover:bg-[#02c974] text-gray-900 p-2 rounded-full transition-colors shadow-lg"
                >
                  <Camera className="w-5 h-5" />
                </button>
              )}
              {uploading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Loader2 className="w-8 h-8 text-[#03E888] animate-spin" />
                </div>
              )}
              <input
                ref={fileInputRef}
                type="file"
                accept="image/jpeg,image/png,image/gif,image/webp"
                onChange={handleFileChange}
                className="hidden"
              />
            </div>

            <div className="w-full">
              {isEditing ? (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2 font-mono">
                      Nombre completo
                    </label>
                    <input
                      type="text"
                      required
                      value={form.full_name}
                      onChange={(e) => setForm({ ...form, full_name: e.target.value })}
                      className="w-full px-4 py-3 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2 font-mono">
                      Teléfono
                    </label>
                    <div className="flex gap-2">
                      <div className="relative">
                        <select
                          value={form.country_code}
                          onChange={(e) => setForm({ ...form, country_code: e.target.value })}
                          className="pl-3 pr-8 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono appearance-none"
                        >
                          {COUNTRY_CODES.map(({ code, country }) => (
                            <option key={code} value={code}>
                              {code}
                            </option>
                          ))}
                        </select>
                        <div className="absolute right-2 top-1/2 -translate-y-1/2 pointer-events-none">
                          <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>
                      </div>
                      <input
                        type="tel"
                        value={form.phone}
                        onChange={(e) => setForm({ ...form, phone: e.target.value })}
                        className="flex-1 px-4 py-3 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono"
                        placeholder="123456789"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2 font-mono">
                      Descripción
                    </label>
                    <textarea
                      value={form.description}
                      onChange={(e) => setForm({ ...form, description: e.target.value })}
                      rows={4}
                      className="w-full px-4 py-3 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] font-mono"
                      placeholder="Cuéntanos un poco sobre ti..."
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full py-3 px-6 bg-[#03E888] hover:bg-[#02c974] text-gray-900 rounded-lg transition-colors duration-300 font-medium font-mono"
                  >
                    Guardar cambios
                  </button>
                </form>
              ) : (
                <div className="space-y-6">
                  <h3 className="text-xl font-semibold text-white font-mono text-center">
                    {profile?.full_name}
                  </h3>

                  {profile?.phone && (
                    <div className="flex items-center justify-center gap-2 text-gray-300 font-mono">
                      <Phone className="w-4 h-4" />
                      <span>{profile.phone}</span>
                    </div>
                  )}

                  <div className="bg-gray-700/30 rounded-lg p-6 border border-gray-700">
                    <p className="text-gray-300 font-mono whitespace-pre-wrap text-center">
                      {profile?.description || 'Sin descripción'}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}