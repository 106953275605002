import React, { useState } from 'react';
import { Plus, Building2, Linkedin } from 'lucide-react';
import { UniversityCard } from './UniversityCard';
import { AddUniversityModal } from './AddUniversityModal';
import { CarList } from './CarList';
import { useUniversities } from '../hooks/useUniversities';
import { useProfile } from '../hooks/useProfile';
import { SearchInput } from './SearchInput';

interface Props {
  user: any;
  onAuthRequired: () => void;
}

export function UniversityList({ user, onAuthRequired }: Props) {
  const { universities, loading, error, addUniversity } = useUniversities();
  const { profile } = useProfile(user);
  const [selectedUniversity, setSelectedUniversity] = useState<string | null>(null);
  const [showAddUniversity, setShowAddUniversity] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleAddUniversity = async (university: { name: string; description: string; logo: string; color: string }) => {
    if (!user) {
      onAuthRequired();
      return;
    }

    if (!profile?.is_admin) {
      alert('Solo los administradores pueden añadir universidades');
      return;
    }

    try {
      await addUniversity(university);
      setShowAddUniversity(false);
    } catch (err) {
      console.error('Error adding university:', err);
      alert('Error al añadir universidad');
    }
  };

  const filteredUniversities = universities.filter(university =>
    university.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    university.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="text-[#03E888] text-xl font-mono">Cargando universidades...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
        {error}
      </div>
    );
  }

  if (selectedUniversity) {
    const university = universities.find(u => u.id === selectedUniversity);
    if (!university) return null;

    return (
      <>
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setSelectedUniversity(null)}
            className="text-[#03E888] hover:text-[#02c974] transition-colors font-mono"
          >
            ← Volver a universidades
          </button>
        </div>
        <CarList 
          universityId={selectedUniversity} 
          universityName={university.name}
          universityColor={university.color}
          onAuthRequired={onAuthRequired} 
        />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 mb-8">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div>
            <h2 className="text-xl font-semibold text-white font-mono">Selecciona tu universidad</h2>
          </div>
          {profile?.is_admin ? (
            <button
              onClick={() => setShowAddUniversity(true)}
              className="w-full sm:w-auto flex items-center justify-center gap-2 bg-[#03E888] hover:bg-[#02c974] text-gray-900 px-4 py-2 rounded-lg transition-colors duration-300 font-mono"
            >
              <Plus className="w-5 h-5" />
              Añadir universidad
            </button>
          ) : (
            <a
              href="https://www.linkedin.com/in/lucas-losantos/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 font-mono text-sm"
            >
              <Building2 className="w-4 h-4" />
              <span>¿Tu universidad no está? Contáctanos</span>
              <Linkedin className="w-4 h-4" />
            </a>
          )}
        </div>

        <SearchInput
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Buscar universidad..."
        />
      </div>

      <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
        {filteredUniversities.map((university) => (
          <UniversityCard
            key={university.id}
            university={university}
            onSelect={setSelectedUniversity}
            isAdmin={profile?.is_admin}
          />
        ))}
      </div>

      {filteredUniversities.length === 0 && searchQuery && (
        <div className="text-center py-12">
          <p className="text-gray-400 font-mono">No se encontraron universidades que coincidan con tu búsqueda.</p>
        </div>
      )}

      {showAddUniversity && profile?.is_admin && (
        <AddUniversityModal
          isOpen={showAddUniversity}
          onClose={() => setShowAddUniversity(false)}
          onAdd={handleAddUniversity}
        />
      )}
    </>
  );
}