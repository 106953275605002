import React, { useState } from 'react';
import { User, Phone, X, MessageCircle } from 'lucide-react';
import { MaterialType } from '../../types';
import { MaterialTag } from '../MaterialTag';
import { WhatsAppButton } from '../WhatsAppButton';
import { useProfile } from '../../hooks/useProfile';

interface Props {
  passengers: Array<{
    id: string;
    name: string;
    avatar_url: string | null;
    contact_info: string;
    materials?: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
    }>;
  }>;
  onRemovePassenger?: (id: string) => Promise<void>;
  onRemoveMaterial?: (id: string) => Promise<void>;
  isUserDriver: boolean;
  currentUserId?: string;
  canRemoveMaterial: (providerId: string) => boolean;
}

export function PassengersList({
  passengers,
  onRemovePassenger,
  onRemoveMaterial,
  isUserDriver,
  currentUserId,
  canRemoveMaterial
}: Props) {
  const [hoveredPassengerId, setHoveredPassengerId] = useState<string | null>(null);
  const [loading, setLoading] = useState<string | null>(null);

  if (!passengers.length) return null;

  const handleRemovePassenger = async (passengerId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onRemovePassenger || loading) return;

    try {
      setLoading(passengerId);
      await onRemovePassenger(passengerId);
    } catch (err) {
      console.error('Error removing passenger:', err);
    } finally {
      setLoading(null);
    }
  };

  const handleRemoveMaterial = async (materialId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onRemoveMaterial || loading) return;

    try {
      setLoading(materialId);
      await onRemoveMaterial(materialId);
    } catch (err) {
      console.error('Error removing material:', err);
    } finally {
      setLoading(null);
    }
  };

  return (
    <div className="p-3 bg-gray-700/30 rounded-lg border border-gray-700">
      <div className="text-sm text-gray-400 font-mono mb-3">Pasajeros:</div>
      <div className="space-y-3">
        {passengers.map((passenger) => {
          const passengerProfile = useProfile({ id: passenger.id });
          const isLoadingPassenger = loading === passenger.id;

          return (
            <div 
              key={passenger.id} 
              className="flex justify-between items-start group relative"
              onMouseEnter={() => setHoveredPassengerId(passenger.id)}
              onMouseLeave={() => setHoveredPassengerId(null)}
            >
              <div className="flex items-center gap-2">
                <div className="w-7 h-7 rounded-full border-2 border-[#03E888] overflow-hidden bg-gray-700">
                  {passenger.avatar_url ? (
                    <img 
                      src={passenger.avatar_url} 
                      alt={passenger.name} 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <User className="w-3.5 h-3.5 text-gray-400" />
                    </div>
                  )}
                </div>
                <div>
                  <span className="text-white font-mono text-sm">
                    {passenger.name}
                  </span>
                  {passengerProfile.profile?.phone && (
                    <div className="text-xs text-gray-400 font-mono mt-0.5 group relative flex items-center gap-1">
                      <Phone className="w-3 h-3" />
                      <span>{passengerProfile.profile.phone}</span>
                      <WhatsAppButton phone={passengerProfile.profile.phone} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2">
                {passenger.materials && passenger.materials.length > 0 && (
                  <div className="flex flex-wrap gap-1 justify-end">
                    {passenger.materials.map((material) => {
                      const isLoadingMaterial = loading === material.id;
                      const canRemove = canRemoveMaterial(passenger.id);
                      
                      return (
                        <div key={material.id} className="relative group">
                          <MaterialTag 
                            type={material.material_type}
                            size="sm"
                            description={material.description}
                          />
                          {canRemove && onRemoveMaterial && (
                            <button
                              onClick={(e) => handleRemoveMaterial(material.id, e)}
                              disabled={isLoadingMaterial}
                              className={`absolute -top-1 -right-1 w-4 h-4 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center ${
                                isLoadingMaterial ? 'cursor-wait opacity-50' : 'hover:bg-red-600'
                              }`}
                            >
                              <X className="w-3 h-3" />
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="flex items-center gap-2">
                  {isUserDriver && onRemovePassenger && (
                    <button
                      onClick={(e) => handleRemovePassenger(passenger.id, e)}
                      disabled={isLoadingPassenger}
                      className={`opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-red-500/10 rounded-lg ${
                        isLoadingPassenger ? 'cursor-wait opacity-50' : ''
                      }`}
                    >
                      <X className="w-4 h-4 text-red-500" />
                    </button>
                  )}
                  {isUserDriver && passenger.contact_info && (
                    <div className="relative">
                      <MessageCircle 
                        className={`w-4 h-4 text-gray-400 hover:text-white transition-colors cursor-help ${
                          hoveredPassengerId === passenger.id ? 'text-white' : ''
                        }`}
                      />
                      {hoveredPassengerId === passenger.id && (
                        <div className="absolute z-10 bottom-full right-0 mb-2 w-64 p-2 bg-gray-900 rounded-lg text-xs font-mono text-white shadow-lg">
                          {passenger.contact_info}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}