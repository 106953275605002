import { User, Phone } from 'lucide-react';
import { MaterialType } from '../../types';
import { MaterialTag } from '../MaterialTag';
import { WhatsAppButton } from '../WhatsAppButton';
import { useProfile } from '../../hooks/useProfile';

interface Props {
  contributors: Array<{
    id: string;
    name: string;
    avatar_url: string | null;
    materials: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
    }>;
  }>;
  onRemoveMaterial?: (id: string) => Promise<void>;
  isUserDriver: boolean;
  currentUserId?: string;
  canRemoveMaterial: (providerId: string) => boolean;
}

export function ContributorsList({
  contributors,
  onRemoveMaterial,
  isUserDriver,
  currentUserId,
  canRemoveMaterial
}: Props) {
  if (!contributors.length) return null;

  return (
    <div className="p-3 bg-gray-700/30 rounded-lg border border-gray-700">
      <div className="text-sm text-gray-400 font-mono mb-3">Contribuciones:</div>
      <div className="space-y-3">
        {contributors.map((contributor) => {
          const contributorProfile = useProfile({ id: contributor.id });
          return (
            <div key={contributor.id} className="flex justify-between items-start">
              <div className="flex items-center gap-2">
                <div className="w-7 h-7 rounded-full border-2 border-purple-500 overflow-hidden bg-gray-700">
                  {contributor.avatar_url ? (
                    <img 
                      src={contributor.avatar_url} 
                      alt={contributor.name} 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <User className="w-3.5 h-3.5 text-gray-400" />
                    </div>
                  )}
                </div>
                <div>
                  <span className="text-white font-mono text-sm">
                    {contributor.name}
                  </span>
                  {contributorProfile.profile?.phone && (
                    <div className="text-xs text-gray-400 font-mono mt-0.5 group relative flex items-center gap-1">
                      <Phone className="w-3 h-3" />
                      <span>{contributorProfile.profile.phone}</span>
                      <WhatsAppButton phone={contributorProfile.profile.phone} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap gap-1 justify-end">
                {contributor.materials.map((material) => (
                  <MaterialTag 
                    key={material.id}
                    type={material.material_type}
                    size="sm"
                    description={material.description}
                    onRemove={
                      canRemoveMaterial(contributor.id) && onRemoveMaterial
                        ? () => onRemoveMaterial(material.id)
                        : undefined
                    }
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}