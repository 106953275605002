import { useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import type { Database } from '../lib/database.types';

type Profile = Database['public']['Tables']['profiles']['Row'];

export function useProfile(user: User | null) {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (user?.id) {
      fetchProfile(user.id);
    } else {
      setLoading(false);
      setProfile(null);
    }
  }, [user?.id]);

  async function fetchProfile(userId: string) {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .maybeSingle();

      if (error) throw error;
      setProfile(data);
    } catch (err) {
      console.error('Error fetching profile:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar perfil');
    } finally {
      setLoading(false);
    }
  }

  async function updateProfile(updates: Partial<Profile>) {
    if (!user?.id) throw new Error('Usuario no autenticado');
    
    try {
      const { data, error } = await supabase
        .from('profiles')
        .upsert([{
          id: user.id,
          ...updates
        }], {
          onConflict: 'id'
        })
        .select()
        .single();

      if (error) throw error;
      setProfile(data);
      return data;
    } catch (err) {
      throw err instanceof Error ? err : new Error('Error al actualizar perfil');
    }
  }

  async function uploadAvatar(file: File) {
    if (!user?.id) throw new Error('Usuario no autenticado');
    
    try {
      if (file.size > 2 * 1024 * 1024) {
        throw new Error('La imagen no puede superar los 2MB');
      }

      if (!file.type.startsWith('image/')) {
        throw new Error('Solo se permiten archivos de imagen');
      }

      const fileExt = file.name.split('.').pop()?.toLowerCase();
      const allowedExts = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
      
      if (!fileExt || !allowedExts.includes(fileExt)) {
        throw new Error('Formato de imagen no válido');
      }

      const fileName = `${Date.now()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      if (profile?.avatar_url) {
        const oldPath = profile.avatar_url.split('/').pop();
        if (oldPath) {
          await supabase.storage
            .from('avatars')
            .remove([`${user.id}/${oldPath}`]);
        }
      }

      const { error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      if (!data.publicUrl) throw new Error('Error al obtener URL de la imagen');

      await updateProfile({ avatar_url: data.publicUrl });
      return data.publicUrl;
    } catch (err) {
      console.error('Upload error:', err);
      throw err instanceof Error ? err : new Error('Error al subir avatar');
    }
  }

  return {
    profile,
    loading,
    error,
    updateProfile,
    uploadAvatar,
    refreshProfile: () => user?.id && fetchProfile(user.id),
  };
}