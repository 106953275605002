import { CountryCode } from '../types';

export const COUNTRY_CODES: CountryCode[] = [
  { code: '+34', country: '🇪🇸', name: 'España' },
  { code: '+33', country: '🇫🇷', name: 'Francia' },
  { code: '+351', country: '🇵🇹', name: 'Portugal' },
  { code: '+39', country: '🇮🇹', name: 'Italia' },
  { code: '+44', country: '🇬🇧', name: 'Reino Unido' },
  { code: '+49', country: '🇩🇪', name: 'Alemania' },
  { code: '+31', country: '🇳🇱', name: 'Países Bajos' },
  { code: '+32', country: '🇧🇪', name: 'Bélgica' },
  { code: '+41', country: '🇨🇭', name: 'Suiza' },
  { code: '+43', country: '🇦🇹', name: 'Austria' },
];