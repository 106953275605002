import React, { useState } from 'react';
import { MessageCircle, Package } from 'lucide-react';
import { MaterialType } from '../../types';
import { MaterialSelector } from '../MaterialSelector';

interface Props {
  onSubmit: (contactInfo: string, materials: MaterialType[]) => Promise<void>;
  showContactInfo?: boolean;
  submitLabel: string;
  loading?: boolean;
}

export function JoinForm({ onSubmit, showContactInfo = true, submitLabel, loading = false }: Props) {
  const [contactInfo, setContactInfo] = useState('');
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialType[]>([]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(contactInfo, selectedMaterials);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {showContactInfo && (
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-1 font-mono flex items-center gap-2">
            <MessageCircle className="w-4 h-4" />
            Comentario al conductor (opcional)
          </label>
          <textarea
            value={contactInfo}
            onChange={(e) => setContactInfo(e.target.value)}
            className="w-full px-3 py-2 bg-gray-700/50 border-2 border-[var(--univ-color-20)] text-white rounded-lg focus:ring-2 focus:ring-[var(--univ-color)] focus:border-[var(--univ-color)] font-mono text-sm"
            placeholder="un saludo, algo interesante, tu dirección :)"
            rows={2}
          />
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-300 mb-2 font-mono flex items-center gap-2">
          <Package className="w-4 h-4" />
          Material que puedes aportar (opcional)
        </label>
        <MaterialSelector
          selected={selectedMaterials}
          onToggle={(type) => {
            setSelectedMaterials(current => 
              current.includes(type)
                ? current.filter(t => t !== type)
                : [...current, type]
            );
          }}
        />
      </div>

      <button
        type="submit"
        disabled={loading}
        className="w-full py-2 px-4 bg-[var(--univ-color)] hover:brightness-110 text-gray-900 rounded-lg transition-all duration-300 font-medium font-mono disabled:opacity-50"
      >
        {loading ? 'Procesando...' : submitLabel}
      </button>
    </form>
  );
}