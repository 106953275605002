import React from 'react';
import { Building2, Pencil, Car } from 'lucide-react';
import type { Database } from '../lib/database.types';
import { SocialLinks } from './SocialLinks';

type University = Database['public']['Tables']['universities']['Row'] & {
  ride_count?: number;
};

interface Props {
  university: University;
  onSelect: (id: string) => void;
  isAdmin?: boolean;
}

export function UniversityCard({ university, onSelect, isAdmin }: Props) {
  const colorStyle = {
    '--univ-color': university.color,
    '--univ-color-10': `${university.color}1a`,
    '--univ-color-20': `${university.color}33`,
    '--univ-color-50': `${university.color}80`,
  } as React.CSSProperties;

  return (
    <div
      onClick={() => onSelect(university.id)}
      className="group relative bg-gray-800/50 backdrop-blur-sm border-2 hover:border-4 hover:-m-[2px] border-[var(--univ-color-50)] rounded-xl shadow-lg hover:shadow-[var(--univ-color-20)] transition-all duration-300 cursor-pointer p-4 sm:p-6"
      style={colorStyle}
    >
      {/* Status indicator */}
      <div className="absolute top-3 sm:top-4 right-3 sm:right-4 flex items-center space-x-1 bg-[var(--univ-color-10)] px-2 sm:px-3 py-1 rounded-full border border-[var(--univ-color-20)]">
        <Car className="w-3 h-3 sm:w-4 sm:h-4 text-[var(--univ-color)]" />
        <span className="text-[var(--univ-color)] font-medium font-mono text-xs sm:text-sm">
          {university.ride_count || 0} viajes
        </span>
      </div>

      {isAdmin && (
        <div className="absolute bottom-3 sm:bottom-4 right-3 sm:right-4 opacity-0 group-hover:opacity-100 transition-opacity">
          <button
            onClick={(e) => {
              e.stopPropagation();
              alert('Edición de universidad próximamente');
            }}
            className="flex items-center gap-1 bg-[var(--univ-color-10)] px-2 py-1 rounded-full border border-[var(--univ-color-20)] text-[var(--univ-color)] font-mono text-xs"
          >
            <Pencil className="w-3 h-3" />
            <span>Editar</span>
          </button>
        </div>
      )}

      <div className="flex items-start">
        {university.logo ? (
          <img
            src={university.logo}
            alt={university.name}
            className="w-12 h-12 sm:w-16 sm:h-16 object-contain rounded-lg"
          />
        ) : (
          <Building2 className="w-12 h-12 sm:w-16 sm:h-16 text-[var(--univ-color)]" />
        )}
      </div>
      
      <div className="mt-3 sm:mt-4">
        <h3 className="text-lg sm:text-xl font-bold text-white font-mono group-hover:text-[var(--univ-color)] transition-colors line-clamp-2">
          {university.name}
        </h3>
        <p className="text-gray-400 mt-2 font-mono text-sm sm:text-base line-clamp-3">
          {university.description}
        </p>
      </div>

      <div className="mt-3 sm:mt-4 flex items-center justify-between">
        <span className="text-[var(--univ-color)] font-mono text-xs sm:text-sm">
          Ver viajes disponibles →
        </span>
        <SocialLinks
          whatsappUrl={university.whatsapp_url}
          telegramUrl={university.telegram_url}
        />
      </div>
    </div>
  );
}