import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Auth from './components/Auth';
import { Dashboard } from './components/Dashboard';
import { useAuth } from './contexts/AuthContext';

export default function App() {
  const { user, loading } = useAuth();
  const [showAuth, setShowAuth] = useState(false);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-[#03E888] text-xl font-mono">Cargando...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      <BrowserRouter>
        <Dashboard 
          session={{ user }} 
          onAuthRequired={() => setShowAuth(true)} 
        />
        {showAuth && <Auth isOpen={showAuth} onClose={() => setShowAuth(false)} />}
      </BrowserRouter>
    </div>
  );
}