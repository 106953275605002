import React from 'react';
import { MessageCircle, Send } from 'lucide-react';

interface Props {
  platform: 'whatsapp' | 'telegram';
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export function SocialLinkInput({ platform, value, onChange, className = '' }: Props) {
  const Icon = platform === 'whatsapp' ? MessageCircle : Send;
  const color = platform === 'whatsapp' ? '#25D366' : '#0088cc';
  const placeholder = platform === 'whatsapp' 
    ? 'https://chat.whatsapp.com/...'
    : 'https://t.me/...';

  return (
    <div className={`relative ${className}`}>
      <Icon 
        className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5" 
        style={{ color }}
      />
      <input
        type="url"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[#03E888]/20 text-white rounded-lg focus:ring-2 focus:ring-[#03E888] focus:border-[#03E888] placeholder-gray-400 font-mono"
      />
    </div>
  );
}