import { Heart, X } from 'lucide-react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function DonationModal({ isOpen, onClose, onConfirm }: Props) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800/90 border-2 border-pink-500/50 rounded-xl max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-3">
            <Heart className="w-6 h-6 text-pink-500" />
            <h2 className="text-xl font-bold text-white font-mono">Donar a los conductores</h2>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-6">
          <p className="text-gray-300 font-mono leading-relaxed">
            Los conductores son fundamentales en esta ayuda. Aunque el precio del trayecto se divide entre los pasajeros, 
            el vehículo suele cubrir riesgos en la zona. Al contribuir, ayudas a recompensar a quienes más se esfuerzan 
            en los trayectos. Todo el dinero irá directamente a los mejores conductores, garantizando transparencia total.
          </p>

          <div className="flex flex-col sm:flex-row gap-3">
            <button
              onClick={onClose}
              className="flex-1 py-2 px-4 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition-colors duration-300 font-medium font-mono"
            >
              Cancelar
            </button>
            <button
              onClick={onConfirm}
              className="flex-1 py-2 px-4 bg-pink-500 hover:bg-pink-600 text-white rounded-lg transition-colors duration-300 font-medium font-mono group"
            >
              <span className="flex items-center justify-center gap-2">
                Confirmar donación
                <Heart className="w-4 h-4 group-hover:scale-110 transition-transform" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}