import { Car, User, Phone } from 'lucide-react';
import { MaterialType } from '../../types';
import { MaterialTag } from '../MaterialTag';
import { WhatsAppButton } from '../WhatsAppButton';
import { useProfile } from '../../hooks/useProfile';

interface Props {
  driverId: string;
  driverName: string;
  driverAvatar?: string | null;
  carType: string;
  availableSeats: number;
  materials?: Array<{
    id: string;
    material_type: MaterialType;
    description?: string;
  }>;
  onRemoveMaterial?: (id: string) => Promise<void>;
  isUserDriver: boolean;
  currentUser?: { id: string } | null;
  universityColor: string;
}

export function DriverInfo({
  driverId,
  driverName,
  driverAvatar,
  carType,
  availableSeats,
  materials,
  onRemoveMaterial,
  isUserDriver,
  currentUser,
  universityColor
}: Props) {
  const { profile: driverProfile } = useProfile({ id: driverId });

  return (
    <div className="p-3 bg-gray-700/30 rounded-lg border border-gray-700">
      <div className="flex justify-between items-start">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-full border-2 border-[#00B7FF] overflow-hidden bg-gray-700">
            {driverAvatar ? (
              <img 
                src={driverAvatar} 
                alt={driverName} 
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <User className="w-5 h-5 text-gray-400" />
              </div>
            )}
          </div>
          <div>
            <div className="text-white font-mono">{driverName}</div>
            <div className="text-sm text-gray-400 font-mono">Conductor</div>
            {driverProfile?.phone && currentUser && !isUserDriver && (
              <div className="text-sm text-gray-400 font-mono mt-1 group relative flex items-center gap-2">
                <Phone className="w-3 h-3" />
                <span>{driverProfile.phone}</span>
                <WhatsAppButton phone={driverProfile.phone} />
              </div>
            )}
          </div>
        </div>
        <div className="text-right space-y-1">
          <div className="flex items-center gap-2 text-white font-mono text-sm justify-end">
            {carType}
            <Car className="w-4 h-4 text-[#00B7FF]" />
          </div>
          <div className="flex items-center gap-2 text-gray-400 font-mono text-sm justify-end">
            {availableSeats} asientos
            <User className="w-4 h-4 text-[#03E888]" />
          </div>
        </div>
      </div>

      {materials && materials.length > 0 && (
        <div className="mt-3 pt-3 border-t border-gray-600">
          <div className="flex flex-wrap gap-1">
            {materials.map((material) => (
              <MaterialTag 
                key={material.id}
                type={material.material_type}
                size="sm"
                description={material.description}
                onRemove={isUserDriver && onRemoveMaterial ? () => onRemoveMaterial(material.id) : undefined}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}