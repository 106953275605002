import React, { useState } from 'react';
import { Car, X, Trash2 } from 'lucide-react';
import { MaterialType } from '../../types';
import { useAuth } from '../../contexts/AuthContext';
import { DeleteConfirmation } from './DeleteConfirmation';
import { DriverInfo } from './DriverInfo';
import { PassengersList } from './PassengersList';
import { ContributorsList } from './ContributorsList';
import { JoinForm } from './JoinForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onJoin: (contactInfo: string, materials?: Array<{ type: MaterialType; description?: string }>) => Promise<void>;
  onContributeMaterials?: (materials: Array<{ type: MaterialType; description?: string }>) => Promise<void>;
  onLeave?: () => Promise<void>;
  onRemovePassenger?: (passengerId: string) => Promise<void>;
  onRemoveMaterial?: (materialId: string) => Promise<void>;
  onDeleteRide?: () => Promise<void>;
  ride: {
    id: string;
    driverName: string;
    driverId: string;
    driverAvatar?: string | null;
    driverAlias?: string;
    carType: string;
    departureTime: string;
    availableSeats: number;
    driverMaterials?: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
    }>;
    passengers?: Array<{
      id: string;
      name: string;
      avatar_url: string | null;
      contact_info: string;
      materials?: Array<{
        id: string;
        material_type: MaterialType;
        description?: string;
      }>;
    }>;
    contributors?: Array<{
      id: string;
      name: string;
      avatar_url: string | null;
      materials: Array<{
        id: string;
        material_type: MaterialType;
        description?: string;
      }>;
    }>;
  };
  universityColor: string;
  currentUserId?: string;
}

export function JoinRideModal({ 
  isOpen, 
  onClose, 
  onJoin,
  onLeave,
  onRemovePassenger,
  onRemoveMaterial,
  onDeleteRide,
  onContributeMaterials,
  ride, 
  universityColor,
  currentUserId 
}: Props) {
  const { user } = useAuth();
  const [showJoinForm, setShowJoinForm] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [joinLoading, setJoinLoading] = useState(false);
  const [joinError, setJoinError] = useState<string | null>(null);

  if (!isOpen) return null;

  const isUserDriver = currentUserId === ride.driverId;
  const isUserInRide = currentUserId && ride.passengers?.some(p => p.id === currentUserId);

  const canRemoveMaterial = (providerId: string) => {
    return isUserDriver || currentUserId === providerId;
  };

  const colorStyle = {
    '--univ-color': universityColor,
    '--univ-color-20': `${universityColor}33`,
  } as React.CSSProperties;

  return (
    <div className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <div 
        className="bg-gray-800/90 border-2 rounded-xl max-w-md w-full p-6 max-h-[90vh] overflow-y-auto"
        style={colorStyle}
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-6 sticky top-0 bg-gray-800/90 backdrop-blur-sm pb-4 border-b border-gray-700">
          <div className="flex items-center gap-3">
            <Car className="w-6 h-6 text-[var(--univ-color)]" />
            <div>
              <h2 className="text-xl font-bold text-white font-mono">
                {isUserInRide ? 'Información del Viaje' : 'Unirse al Viaje'}
              </h2>
              <p className="text-sm text-gray-400 font-mono">{ride.driverName}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {isUserDriver && (
              <button
                onClick={() => setShowConfirmDelete(true)}
                className="text-red-400 hover:text-red-300 p-2 rounded-lg transition-colors"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            )}
            <button onClick={onClose} className="text-gray-400 hover:text-white">
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>

        {showConfirmDelete && (
          <DeleteConfirmation
            onCancel={() => setShowConfirmDelete(false)}
            onConfirm={async () => {
              if (onDeleteRide) {
                await onDeleteRide();
                onClose();
              }
            }}
          />
        )}

        <div className="space-y-6">
          {/* Driver Info */}
          <DriverInfo
            driverId={ride.driverId}
            driverName={ride.driverName}
            driverAvatar={ride.driverAvatar}
            carType={ride.carType}
            availableSeats={ride.availableSeats}
            materials={ride.driverMaterials}
            onRemoveMaterial={onRemoveMaterial}
            isUserDriver={isUserDriver}
            currentUser={user}
            universityColor={universityColor}
          />

          {/* Passengers List */}
          {ride.passengers && ride.passengers.length > 0 && (
            <PassengersList
              passengers={ride.passengers}
              onRemovePassenger={onRemovePassenger}
              onRemoveMaterial={onRemoveMaterial}
              isUserDriver={isUserDriver}
              currentUserId={currentUserId}
              canRemoveMaterial={canRemoveMaterial}
            />
          )}

          {/* Contributors List */}
          {ride.contributors && ride.contributors.length > 0 && (
            <ContributorsList
              contributors={ride.contributors}
              onRemoveMaterial={onRemoveMaterial}
              isUserDriver={isUserDriver}
              currentUserId={currentUserId}
              canRemoveMaterial={canRemoveMaterial}
            />
          )}

          {user && !isUserDriver && (
            <>
              {joinError && (
                <div className="p-3 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
                  {joinError}
                </div>
              )}

              {!isUserInRide && ride.availableSeats > 0 && (
                <div className="p-3 bg-gray-700/30 rounded-lg border border-gray-700">
                  <div className="flex justify-center mb-4">
                    <div className="flex w-full p-1 bg-gray-800/50 backdrop-blur-sm rounded-lg border border-gray-600">
                      <button
                        onClick={() => setShowJoinForm(true)}
                        className={`flex-1 py-1.5 rounded-lg transition-colors font-mono text-sm ${
                          showJoinForm
                            ? 'bg-[#03E888] text-gray-900'
                            : 'text-gray-400 hover:text-white'
                        }`}
                      >
                        Unirme
                      </button>
                      <button
                        onClick={() => setShowJoinForm(false)}
                        className={`flex-1 py-1.5 rounded-lg transition-colors font-mono text-sm ${
                          !showJoinForm
                            ? 'bg-purple-500 text-white'
                            : 'text-gray-400 hover:text-white'
                        }`}
                      >
                        Contribuir
                      </button>
                    </div>
                  </div>

                  <JoinForm
                    onSubmit={async (contactInfo, materials) => {
                      try {
                        setJoinLoading(true);
                        setJoinError(null);
                        if (showJoinForm) {
                          await onJoin(contactInfo, materials.map(type => ({ type })));
                        } else if (onContributeMaterials) {
                          await onContributeMaterials(materials.map(type => ({ type })));
                        }
                        onClose();
                      } catch (err) {
                        console.error('Error:', err);
                        setJoinError(err instanceof Error ? err.message : 'Ha ocurrido un error');
                      } finally {
                        setJoinLoading(false);
                      }
                    }}
                    showContactInfo={showJoinForm}
                    submitLabel={showJoinForm ? 'Unirme al Viaje' : 'Contribuir'}
                    loading={joinLoading}
                  />
                </div>
              )}

              {isUserInRide && !isUserDriver && onLeave && (
                <button
                  onClick={onLeave}
                  className="w-full py-2 px-4 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors duration-300 font-medium font-mono"
                >
                  Abandonar Viaje
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}