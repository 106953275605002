import { Heart } from 'lucide-react';

interface Props {
  onClick: () => void;
}

export function DonationButton({ onClick }: Props) {
  return (
    <button
      onClick={onClick}
      className="w-full sm:w-auto flex items-center justify-center gap-2 bg-pink-500 hover:bg-pink-600 text-white px-6 py-3 rounded-lg transition-all duration-300 font-medium font-mono group animate-pulse hover:animate-none"
    >
      <Heart className="w-5 h-5 group-hover:scale-110 transition-transform" />
      Donar a los conductores
    </button>
  );
}