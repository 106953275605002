import React from 'react';
import { Clock, Users, ArrowRight, ArrowLeft, User, Check } from 'lucide-react';
import type { Car, MaterialType } from '../types';
import { MaterialTag } from './MaterialTag';

interface Props {
  car: Car & {
    materials?: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
      provider_type: 'driver' | 'passenger' | 'contributor';
      provider_id: string;
      provider_name: string;
      provider_avatar?: string | null;
    }>;
  };
  onJoinRide: (carId: string) => void;
  universityColor: string;
  driverName: string;
  driverAvatar?: string | null;
  currentUserId?: string;
  passengers?: Array<{
    id: string;
    name: string;
    avatar_url: string | null;
    materials?: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
    }>;
  }>;
  contributors?: Array<{
    id: string;
    name: string;
    avatar_url: string | null;
    materials: Array<{
      id: string;
      material_type: MaterialType;
      description?: string;
    }>;
  }>;
}

export function CarCard({ 
  car, 
  onJoinRide, 
  universityColor, 
  driverName, 
  driverAvatar, 
  passengers = [],
  contributors = [],
  currentUserId 
}: Props) {
  if (!car || typeof car.availableSeats === 'undefined' || typeof car.totalSeats === 'undefined') {
    return null;
  }

  const formatDriverName = (name: string) => {
    const names = name.split(' ');
    if (names.length === 1 || name.length <= 11) return name;
    
    const firstName = names[0];
    const secondInitial = names[1]?.[0];
    return `${firstName} ${secondInitial}.`;
  };

  const getAvailabilityColor = () => {
    const ratio = car.availableSeats / car.totalSeats;
    if (ratio >= 0.7) return '#10b981';
    if (ratio >= 0.3) return '#f59e0b';
    return '#ef4444';
  };

  const getTripTypeStyles = (tripType: 'to' | 'from' | 'both') => {
    switch (tripType) {
      case 'to':
        return {
          text: 'Hacia DANA',
          bgColor: '#10b98120',
          borderColor: '#10b98140',
          textColor: '#10b981',
          TripIcon: () => <ArrowRight className="w-5 h-5" />
        };
      case 'from':
        return {
          text: 'Desde DANA',
          bgColor: '#f59e0b20',
          borderColor: '#f59e0b40',
          textColor: '#f59e0b',
          TripIcon: () => <ArrowLeft className="w-5 h-5" />
        };
      default:
        return {
          text: 'Ida y Vuelta',
          bgColor: '#8b5cf620',
          borderColor: '#8b5cf640',
          textColor: '#8b5cf6',
          TripIcon: () => (
            <div className="flex gap-1">
              <ArrowRight className="w-5 h-5" />
              <ArrowLeft className="w-5 h-5" />
            </div>
          )
        };
    }
  };

  const groupedMaterials = car.materials?.reduce((acc, material) => {
    if (!acc[material.material_type]) {
      acc[material.material_type] = {
        count: 1,
        descriptions: material.description ? [material.description] : []
      };
    } else {
      acc[material.material_type].count++;
      if (material.description) {
        acc[material.material_type].descriptions.push(material.description);
      }
    }
    return acc;
  }, {} as Record<MaterialType, { count: number; descriptions: string[] }>);

  const tripStyle = getTripTypeStyles(car.tripType);
  const { TripIcon } = tripStyle;
  const availabilityColor = getAvailabilityColor();

  const getDayInfo = (date: Date) => {
    const days = ['DOM', 'LUN', 'MAR', 'MIÉ', 'JUE', 'VIE', 'SÁB'];
    return {
      day: days[date.getDay()],
      date: date.getDate(),
    };
  };

  const departureDate = new Date(car.departureTime);
  const dayInfo = getDayInfo(departureDate);

  const isUserDriver = currentUserId && currentUserId === car.driverId;
  const isUserPassenger = currentUserId && passengers.some(p => p.id === currentUserId);
  const isUserContributor = currentUserId && contributors?.some(c => c.id === currentUserId);

  // Check if ride is in the past
  const isPastRide = departureDate < new Date();

  return (
    <div 
      className={`group relative bg-gray-800/50 backdrop-blur-sm border-2 rounded-lg shadow-lg transition-all duration-300 p-6 ${
        isUserDriver ? 'border-[#00B7FF]' : 
        isUserPassenger ? 'border-[#03E888]' :
        isUserContributor ? 'border-purple-500' :
        'border-[var(--univ-color)]'
      } ${isPastRide ? 'opacity-50' : ''}`}
      style={{ 
        '--univ-color': universityColor 
      } as React.CSSProperties}
    >
      {(isUserDriver || isUserPassenger || isUserContributor) && (
        <div 
          className="absolute -top-2 -right-2 text-gray-900 rounded-full p-1 shadow-lg"
          style={{ 
            backgroundColor: isUserDriver ? '#00B7FF' : 
                           isUserPassenger ? '#03E888' :
                           '#9333EA' // purple-600
          }}
        >
          <Check className="w-4 h-4" />
        </div>
      )}
      
      <div className="space-y-3 mb-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold text-white font-mono group-hover:text-[#03E888] transition-colors">
            {car.driverAlias || formatDriverName(driverName)}
          </h3>
          <div 
            className="flex items-center space-x-1 px-3 py-1 rounded-full"
            style={{ 
              backgroundColor: `${availabilityColor}20`,
              borderColor: `${availabilityColor}40`,
              borderWidth: '1px'
            }}
          >
            <Users className="w-4 h-4" style={{ color: availabilityColor }} />
            <span className="font-medium font-mono" style={{ color: availabilityColor }}>
              {car.availableSeats}/{car.totalSeats}
            </span>
          </div>
        </div>

        <div className="flex items-center gap-4">
          {/* Driver Group */}
          <div className="flex items-center">
            <div className="relative group/avatar">
              <div className="w-8 h-8 rounded-full border-2 border-[#00B7FF] overflow-hidden bg-gray-700 shadow-lg">
                {driverAvatar ? (
                  <img src={driverAvatar} alt={driverName} className="w-full h-full object-cover" />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <User className="w-4 h-4 text-gray-400" />
                  </div>
                )}
              </div>
              <div className="opacity-0 group-hover/avatar:opacity-100 absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-black/75 text-white text-xs font-mono rounded whitespace-nowrap transition-opacity">
                {formatDriverName(driverName)}
              </div>
            </div>
          </div>

          {/* Passengers Group */}
          {passengers.length > 0 && (
            <div className="flex items-center border-l border-gray-700/50 pl-4">
              <div className="flex -space-x-2">
                {passengers.map((passenger, index) => (
                  <div 
                    key={passenger.id} 
                    className="relative group/avatar"
                    style={{ zIndex: 20 - index }}
                  >
                    <div className="w-8 h-8 rounded-full border-2 border-[#03E888] overflow-hidden bg-gray-700 shadow-lg">
                      {passenger.avatar_url ? (
                        <img src={passenger.avatar_url} alt={passenger.name} className="w-full h-full object-cover" />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <User className="w-4 h-4 text-gray-400" />
                        </div>
                      )}
                    </div>
                    <div className="opacity-0 group-hover/avatar:opacity-100 absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-black/75 text-white text-xs font-mono rounded whitespace-nowrap transition-opacity">
                      {formatDriverName(passenger.name)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Contributors Group */}
          {contributors && contributors.length > 0 && (
            <div className="flex items-center border-l border-gray-700/50 pl-4">
              <div className="flex -space-x-2">
                {contributors.map((contributor, index) => (
                  <div 
                    key={contributor.id} 
                    className="relative group/avatar"
                    style={{ zIndex: 20 - index - passengers.length }}
                  >
                    <div className="w-8 h-8 rounded-full border-2 border-purple-500 overflow-hidden bg-gray-700 shadow-lg">
                      {contributor.avatar_url ? (
                        <img src={contributor.avatar_url} alt={contributor.name} className="w-full h-full object-cover" />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <User className="w-4 h-4 text-gray-400" />
                        </div>
                      )}
                    </div>
                    <div className="opacity-0 group-hover/avatar:opacity-100 absolute -top-8 left-1/2 -translate-x-1/2 px-2 py-1 bg-black/75 text-white text-xs font-mono rounded whitespace-nowrap transition-opacity">
                      {formatDriverName(contributor.name)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div 
        className="flex items-center gap-2 p-2 rounded-lg mb-4"
        style={{ 
          backgroundColor: tripStyle.bgColor,
          borderColor: tripStyle.borderColor,
          borderWidth: '1px'
        }}
      >
        <TripIcon />
        <span className="font-mono text-sm" style={{ color: tripStyle.textColor }}>
          {tripStyle.text}
        </span>
      </div>

      <div className="space-y-2 p-3 bg-gray-700/30 rounded-lg border border-gray-700">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-[#03E888]" />
            <span className="text-white font-mono">
              {departureDate.toLocaleTimeString('es-ES', {
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
          </div>
          <span className="font-mono text-sm text-indigo-400">
            {dayInfo.day} {dayInfo.date}
          </span>
        </div>
        {car.returnTime && (
          <div className="flex items-center justify-between border-t border-gray-700/50 pt-2">
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4 text-[#03E888]" />
              <span className="text-white font-mono">
                {new Date(car.returnTime).toLocaleTimeString('es-ES', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </span>
            </div>
            <span className="font-mono text-sm text-indigo-400">
              {getDayInfo(new Date(car.returnTime)).day} {getDayInfo(new Date(car.returnTime)).date}
            </span>
          </div>
        )}
      </div>

      {groupedMaterials && Object.entries(groupedMaterials).length > 0 && (
        <div className="mt-4 flex flex-wrap gap-1.5">
          {Object.entries(groupedMaterials).map(([type, { count, descriptions }]) => (
            <MaterialTag 
              key={type}
              type={type as MaterialType}
              size="sm"
              description={descriptions.join(' | ')}
              count={count > 1 ? count : undefined}
            />
          ))}
        </div>
      )}

      <div className="absolute inset-x-0 bottom-0 left-0 right-0 p-4 opacity-0 translate-y-2 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-300">
        <button
          onClick={() => onJoinRide(car.id)}
          disabled={isPastRide && !isUserDriver && !isUserPassenger && !isUserContributor}
          className="w-full py-2 px-4 bg-[#03E888] hover:brightness-110 text-gray-900 rounded-lg transition-all duration-300 font-medium font-mono shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isUserDriver ? 'Ver información' : 
           isUserPassenger ? 'Ver información' :
           isUserContributor ? 'Ver información' :
           isPastRide ? 'Viaje pasado' :
           car.availableSeats === 0 ? 'Ver información' : 
           'Unirse al Viaje'}
        </button>
      </div>
    </div>
  );
}