import React from 'react';
import { X } from 'lucide-react';
import type { MaterialType } from '../types';

interface Props {
  type: MaterialType;
  size?: 'sm' | 'md';
  description?: string;
  count?: number;
  onRemove?: () => Promise<void>;
}

const MATERIAL_COLORS: Record<MaterialType, { bg: string; text: string }> = {
  higiene: {
    bg: '#00B7FF',
    text: '#FFFFFF'
  },
  ropa: {
    bg: '#FF6B6B',
    text: '#FFFFFF'
  },
  ninos: {
    bg: '#4ECDC4',
    text: '#FFFFFF'
  },
  alimentos: {
    bg: '#F1C40F',
    text: '#000000'
  },
  mobiliario: {
    bg: '#9B59B6',
    text: '#FFFFFF'
  },
  herramientas: {
    bg: '#E67E22',
    text: '#FFFFFF'
  },
  medicamentos: {
    bg: '#E74C3C',
    text: '#FFFFFF'
  },
  agua: {
    bg: '#3498DB',
    text: '#FFFFFF'
  }
};

const MATERIAL_LABELS: Record<MaterialType, string> = {
  higiene: 'Higiene',
  ropa: 'Ropa',
  ninos: 'Niños',
  alimentos: 'Alimentos',
  mobiliario: 'Mobiliario',
  herramientas: 'Herramientas',
  medicamentos: 'Medicamentos',
  agua: 'Agua'
};

export function MaterialTag({ type, size = 'md', description, count, onRemove }: Props) {
  const colors = MATERIAL_COLORS[type];
  
  return (
    <div className="relative group inline-block">
      <div className="relative">
        <span
          className={`inline-flex items-center rounded-full font-mono ${
            size === 'sm' ? 'px-2 py-0.5 text-xs' : 'px-3 py-1 text-sm'
          }`}
          style={{
            backgroundColor: colors.bg,
            color: colors.text,
            opacity: 0.9
          }}
        >
          {MATERIAL_LABELS[type]}
        </span>
        {count && count > 1 && (
          <span className="absolute -top-1 -right-1 w-4 h-4 flex items-center justify-center text-[10px] rounded-full font-bold bg-gray-900 text-white">
            {count}
          </span>
        )}
        {onRemove && (
          <button
            onClick={onRemove}
            className="absolute -top-1 -right-1 w-4 h-4 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center"
          >
            <X className="w-3 h-3" />
          </button>
        )}
      </div>
      {description && (
        <div className="opacity-0 group-hover:opacity-100 transition-opacity absolute bottom-full left-1/2 -translate-x-1/2 mb-1 px-2 py-1 bg-gray-900 text-xs text-white rounded whitespace-nowrap z-10">
          {description}
        </div>
      )}
    </div>
  );
}