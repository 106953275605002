import React from 'react';
import { Calendar, Clock } from 'lucide-react';

interface Props {
  value: string;
  onChange: (value: string) => void;
  label: string;
  required?: boolean;
  min?: string;
  className?: string;
}

export function DateTimeSelector({ value, onChange, label, required = false, min, className = '' }: Props) {
  // Format the datetime string to local date and time
  const datetime = value ? new Date(value) : new Date();
  const dateValue = datetime.toISOString().split('T')[0];
  const timeValue = datetime.toTimeString().slice(0, 5);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    const currentTime = value ? new Date(value).toTimeString().slice(0, 5) : timeValue;
    const newDatetime = new Date(`${newDate}T${currentTime}`);
    onChange(newDatetime.toISOString());
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentDate = value ? new Date(value).toISOString().split('T')[0] : dateValue;
    const newTime = e.target.value;
    const newDatetime = new Date(`${currentDate}T${newTime}`);
    onChange(newDatetime.toISOString());
  };

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-300 mb-1 font-mono">
        {label}
      </label>
      <div className="flex flex-col xs:flex-row gap-2">
        <div className="relative flex-1">
          <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="date"
            required={required}
            value={dateValue}
            onChange={handleDateChange}
            min={min ? new Date(min).toISOString().split('T')[0] : undefined}
            className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[var(--univ-color-20)] text-white rounded-lg focus:ring-2 focus:ring-[var(--univ-color)] focus:border-[var(--univ-color)] font-mono"
          />
        </div>
        <div className="relative flex-1">
          <Clock className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-gray-400" />
          <input
            type="time"
            required={required}
            value={timeValue}
            onChange={handleTimeChange}
            className="w-full pl-10 pr-3 py-2 bg-gray-700/50 border-2 border-[var(--univ-color-20)] text-white rounded-lg focus:ring-2 focus:ring-[var(--univ-color)] focus:border-[var(--univ-color)] font-mono"
          />
        </div>
      </div>
    </div>
  );
}