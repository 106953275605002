import React, { useState } from 'react';
import { Plus, Users, Linkedin } from 'lucide-react';
import { GroupCard } from './GroupCard';
import { AddGroupModal } from './AddGroupModal';
import { CarList } from './CarList';
import { useGroups } from '../hooks/useGroups';
import { useProfile } from '../hooks/useProfile';
import { SearchInput } from './SearchInput';

interface Props {
  user: any;
  onAuthRequired: () => void;
}

export function GroupList({ user, onAuthRequired }: Props) {
  const { groups, loading, error, addGroup } = useGroups();
  const { profile } = useProfile(user);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleAddGroup = async (group: { name: string; description: string; logo: string; color: string }) => {
    if (!user) {
      onAuthRequired();
      return;
    }

    if (!profile?.is_admin) {
      alert('Solo los administradores pueden añadir grupos');
      return;
    }

    try {
      await addGroup(group);
      setShowAddGroup(false);
    } catch (err) {
      console.error('Error adding group:', err);
      alert('Error al añadir grupo');
    }
  };

  const filteredGroups = groups.filter(group =>
    group.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    group.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="text-[#03E888] text-xl font-mono">Cargando grupos...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-500/10 border border-red-500/50 rounded-lg text-red-500 text-sm font-mono">
        {error}
      </div>
    );
  }

  if (selectedGroup) {
    const group = groups.find(g => g.id === selectedGroup);
    if (!group) return null;

    return (
      <>
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={() => setSelectedGroup(null)}
            className="text-[#03E888] hover:text-[#02c974] transition-colors font-mono"
          >
            ← Volver a grupos
          </button>
        </div>
        <CarList 
          universityId={selectedGroup} 
          universityName={group.name}
          universityColor={group.color}
          onAuthRequired={onAuthRequired} 
        />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 mb-8">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div>
            <h2 className="text-xl font-semibold text-white font-mono">Selecciona tu grupo</h2>
          </div>
          {profile?.is_admin ? (
            <button
              onClick={() => setShowAddGroup(true)}
              className="w-full sm:w-auto flex items-center justify-center gap-2 bg-[#03E888] hover:bg-[#02c974] text-gray-900 px-4 py-2 rounded-lg transition-colors duration-300 font-mono"
            >
              <Plus className="w-5 h-5" />
              Añadir grupo
            </button>
          ) : (
            <a
              href="https://www.linkedin.com/in/lucas-losantos/"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full sm:w-auto flex items-center justify-center gap-2 bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors duration-300 font-mono text-sm"
            >
              <Users className="w-4 h-4" />
              <span>¿Tu grupo no está? Contáctanos</span>
              <Linkedin className="w-4 h-4" />
            </a>
          )}
        </div>

        <SearchInput
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Buscar grupo..."
        />
      </div>

      <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6">
        {filteredGroups.map((group) => (
          <GroupCard
            key={group.id}
            group={group}
            onSelect={setSelectedGroup}
            isAdmin={profile?.is_admin}
          />
        ))}
      </div>

      {filteredGroups.length === 0 && searchQuery && (
        <div className="text-center py-12">
          <p className="text-gray-400 font-mono">No se encontraron grupos que coincidan con tu búsqueda.</p>
        </div>
      )}

      {showAddGroup && profile?.is_admin && (
        <AddGroupModal
          isOpen={showAddGroup}
          onClose={() => setShowAddGroup(false)}
          onAdd={handleAddGroup}
        />
      )}
    </>
  );
}