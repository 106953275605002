import React from 'react';

interface Props {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}

export function DeleteConfirmation({ onCancel, onConfirm }: Props) {
  return (
    <div className="mb-4 p-4 bg-red-500/10 border border-red-500/50 rounded-lg">
      <p className="text-white font-mono mb-3">¿Estás seguro de que quieres eliminar este viaje?</p>
      <div className="flex justify-end gap-2">
        <button
          onClick={onCancel}
          className="px-3 py-1.5 bg-gray-700 text-white rounded-lg font-mono text-sm hover:bg-gray-600 transition-colors"
        >
          Cancelar
        </button>
        <button
          onClick={onConfirm}
          className="px-3 py-1.5 bg-red-500 text-white rounded-lg font-mono text-sm hover:bg-red-600 transition-colors"
        >
          Eliminar
        </button>
      </div>
    </div>
  );
}