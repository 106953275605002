import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import type { Database } from '../lib/database.types';
import type { MaterialType } from '../types';

type Ride = Database['public']['Tables']['rides']['Row'];
type Profile = Database['public']['Tables']['profiles']['Row'];

interface RideWithDetails extends Ride {
  profiles: Profile;
  ride_passengers?: Array<{
    passenger_id: string;
    contact_info: string;
    profiles: Profile;
  }>;
  ride_materials?: Array<{
    id: string;
    material_type: MaterialType;
    description: string;
    provider_id: string;
    provider_type: 'driver' | 'passenger' | 'contributor';
    profiles: Profile;
  }>;
}

export function useRides(universityId: string) {
  const [rides, setRides] = useState<RideWithDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (universityId) {
      fetchRides();
    }
  }, [universityId]);

  async function fetchRides() {
    try {
      setLoading(true);
      
      // Get rides from yesterday onwards
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      const { data, error } = await supabase
        .from('rides')
        .select(`
          *,
          profiles!rides_driver_id_fkey (*),
          ride_passengers (
            passenger_id,
            contact_info,
            profiles (*)
          ),
          ride_materials (
            id,
            material_type,
            description,
            provider_id,
            provider_type,
            profiles (*)
          )
        `)
        .eq('university_id', universityId)
        .gte('departure_time', yesterday.toISOString())
        .order('departure_time', { ascending: true });

      if (error) throw error;
      setRides(data || []);
    } catch (err) {
      console.error('Error loading rides:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar viajes');
    } finally {
      setLoading(false);
    }
  }

  async function addRide(ride: {
    car_type: string;
    total_seats: number;
    trip_type: 'to' | 'from' | 'both';
    departure_time: string;
    return_time?: string;
    driver_alias?: string;
    materials?: Array<{ material_type: MaterialType; description?: string }>;
  }) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Usuario no autenticado');

      const newRide = {
        driver_id: user.id,
        university_id: universityId,
        available_seats: ride.total_seats,
        car_type: ride.car_type,
        total_seats: ride.total_seats,
        trip_type: ride.trip_type,
        departure_time: ride.departure_time,
        return_time: ride.return_time,
        driver_alias: ride.driver_alias
      };

      const { data: rideData, error: rideError } = await supabase
        .from('rides')
        .insert([newRide])
        .select()
        .single();

      if (rideError) throw rideError;

      if (ride.materials && ride.materials.length > 0) {
        const materialsToAdd = ride.materials.map(material => ({
          ride_id: rideData.id,
          provider_id: user.id,
          provider_type: 'driver' as const,
          material_type: material.material_type,
          description: material.description || ''
        }));

        const { error: materialsError } = await supabase
          .from('ride_materials')
          .insert(materialsToAdd);

        if (materialsError) throw materialsError;
      }

      await fetchRides();
      return rideData;
    } catch (err) {
      console.error('Error adding ride:', err);
      throw err instanceof Error ? err : new Error('Error al añadir viaje');
    }
  }

  async function joinRide(rideId: string, contactInfo: string, materials?: Array<{ type: MaterialType; description?: string }>) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Usuario no autenticado');

      // Insert passenger first - the trigger will handle seat management
      const { error: joinError } = await supabase
        .from('ride_passengers')
        .insert([{
          ride_id: rideId,
          passenger_id: user.id,
          contact_info: contactInfo
        }]);

      if (joinError) {
        if (joinError.code === '23505') {
          throw new Error('Ya estás registrado en este viaje');
        }
        throw joinError;
      }

      // Add materials if any
      if (materials && materials.length > 0) {
        const materialsToAdd = materials.map(material => ({
          ride_id: rideId,
          provider_id: user.id,
          provider_type: 'passenger' as const,
          material_type: material.type,
          description: material.description || ''
        }));

        const { error: materialsError } = await supabase
          .from('ride_materials')
          .insert(materialsToAdd);

        if (materialsError) throw materialsError;
      }

      await fetchRides();
    } catch (err) {
      console.error('Error joining ride:', err);
      throw err instanceof Error ? err : new Error('Error al unirse al viaje');
    }
  }

  async function leaveRide(rideId: string) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Usuario no autenticado');

      await removePassenger(rideId, user.id);
    } catch (err) {
      console.error('Error leaving ride:', err);
      throw err instanceof Error ? err : new Error('Error al abandonar el viaje');
    }
  }

  async function removePassenger(rideId: string, passengerId: string) {
    try {
      const { error } = await supabase
        .rpc('remove_passenger', {
          p_ride_id: rideId,
          p_passenger_id: passengerId
        });

      if (error) throw error;
      await fetchRides();
    } catch (err) {
      console.error('Error removing passenger:', err);
      throw err instanceof Error ? err : new Error('Error al eliminar pasajero');
    }
  }

  async function removeMaterial(materialId: string) {
    try {
      const { error } = await supabase
        .from('ride_materials')
        .delete()
        .eq('id', materialId);

      if (error) throw error;
      await fetchRides();
    } catch (err) {
      console.error('Error removing material:', err);
      throw err instanceof Error ? err : new Error('Error al eliminar material');
    }
  }

  async function deleteRide(rideId: string) {
    try {
      const { error } = await supabase
        .from('rides')
        .delete()
        .eq('id', rideId);

      if (error) throw error;
      await fetchRides();
    } catch (err) {
      console.error('Error deleting ride:', err);
      throw err instanceof Error ? err : new Error('Error al eliminar viaje');
    }
  }

  async function contributeMaterials(rideId: string, materials: Array<{ type: MaterialType; description?: string }>) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Usuario no autenticado');

      const materialsToAdd = materials.map(material => ({
        ride_id: rideId,
        provider_id: user.id,
        provider_type: 'contributor' as const,
        material_type: material.type,
        description: material.description || ''
      }));

      const { error } = await supabase
        .from('ride_materials')
        .insert(materialsToAdd);

      if (error) throw error;
      await fetchRides();
    } catch (err) {
      console.error('Error contributing materials:', err);
      throw err instanceof Error ? err : new Error('Error al contribuir materiales');
    }
  }

  return {
    rides,
    loading,
    error,
    addRide,
    joinRide,
    leaveRide,
    removePassenger,
    removeMaterial,
    deleteRide,
    contributeMaterials,
    refreshRides: fetchRides,
  };
}